import { X } from 'lucide-react'
import { Dialog as DialogRoot } from '@headlessui/react'
import { type ReactNode } from 'react'
import { cn } from '~/utils/misc.tsx'
import { Separator } from './separator.tsx'

export type DialogProps = {
  onClose: () => void
  open: boolean
  children: ReactNode
  className?: string
  containerClassName?: string
  variant?: 'center' | 'bottom'
}
const Dialog = ({
  onClose,
  open,
  children,
  className,
  containerClassName,
  variant = 'center',
}: DialogProps) => {
  const centerVariant =
    'left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] max-w-[40rem]'
  const bottomVariant = 'bottom-0 left-0 right-0 !w-full'
  return (
    // Dialog onClose doesn't work
    <DialogRoot onClose={() => null} open={open}>
      <div
        onClick={onClose}
        className="bg-background/90 fixed inset-0 z-[4] backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
      />
      <DialogRoot.Panel
        className={cn(
          'fixed z-10 grid w-full   gap-4 border  bg-card shadow-lg  duration-0 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:w-[calc(100%-24px)] sm:rounded-lg md:w-full',
          variant === 'center' ? centerVariant : bottomVariant,
          className,
        )}
      >
        <div
          className={cn(
            ' h-full max-h-[100vh] space-y-0 overflow-y-auto overflow-x-hidden p-6 sm:max-h-[calc(100vh-24px)]',
            containerClassName,
          )}
        >
          <div className="flex h-full flex-col">{children}</div>
        </div>
        <div
          onClick={onClose}
          className={cn(
            'absolute right-2 top-2 cursor-pointer rounded-full p-1 ',
            variant === 'center' &&
              'sm:-right-3 sm:-top-3 sm:bg-secondary sm:text-white',
          )}
        >
          <X className="h-5 w-5" />
          <span className="sr-only">Close</span>
        </div>
      </DialogRoot.Panel>
    </DialogRoot>
  )
}

interface DialogHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'default' | 'separator-left' | 'separator-bottom'
}

const DialogHeader = ({ variant = 'default', children }: DialogHeaderProps) => {
  return variant === 'separator-left' ? (
    <DialogRoot.Title>
      <Separator textAlign="left" className="bg-secondary">
        <h2 className="text-h5 font-bold text-secondary-header">{children}</h2>
      </Separator>
    </DialogRoot.Title>
  ) : variant === 'separator-bottom' ? (
    <>
      <DialogRoot.Title className="flex items-center justify-center py-4 font-semibold uppercase text-secondary">
        {children}
      </DialogRoot.Title>
      <Separator className="!mt-0" />
    </>
  ) : (
    <div className="flex justify-center font-bold text-secondary">
      {children}
    </div>
  )
}

const DialogTitle = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <DialogRoot.Title
      className={cn(
        'text-lg font-semibold leading-none tracking-tight',
        className,
      )}
    >
      {children}
    </DialogRoot.Title>
  )
}

const DialogDescription = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <DialogRoot.Description
      className={cn('text-sm text-foreground-muted', className)}
    >
      {children}
    </DialogRoot.Description>
  )
}

export { Dialog, DialogHeader, DialogTitle, DialogDescription }
